<template>
  <div class="email-editor">
    <div vclass="container">
      <v-form ref="templateForm" @submit.prevent="saveDesign">
        <div id="bar" style="margin: 5px auto; width: 360px">
          <v-btn color="primary" type="submit" small>Save Design</v-btn>
          &nbsp;
          <v-btn
            color="primary"
            :disabled="!template"
            type="button"
            small
            @click="exportHtml"
          >
            Export HTML
          </v-btn>
          |
          <v-btn
            color="secondary"
            small
            @click="$router.push('/marketing?tab=campaign')"
          >
            Back
          </v-btn>
        </div>
        <v-text-field
          v-model="name"
          :rules="nameRules"
          style="margin: 5px auto; width: 70%"
          label="Template Name"
        />

        <br /><br />

        <div style="margin: 5px auto; width: 90%">
          <email-editor
            :appearance="appearance"
            :min-height="minHeight"
            :project-id="projectId"
            :locale="locale"
            :tools="tools"
            :options="options"
            ref="emailEditor"
            v-on:load="editorLoaded"
            v-on:ready="editorReady"
          />
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { EmailEditor } from "vue-email-editor";

import vendorEmailTemplate from "@/store/modules/vendorEmailTemplate";

const { mapActions: vETemplateActions } = createNamespacedHelpers(
  "VENDOR_EMAIL_TEMPLATE"
);

export default {
  name: "EmailBuilder",
  components: {
    "email-editor": EmailEditor,
  },
  data: () => ({
    minHeight: "1000px",
    locale: "en",
    projectId: 268695, // replace with your project id
    tools: {
      image: {
        enabled: true,
      },
    },
    options: {
      mergeTags: {
        clientNname: {
          name: "Client Name",
          value: "{{client_name}}",
          // sample: "John",
        },
        businessName: {
          name: "Business Name",
          value: "{{business_name}}",
        },
        businessPhone: {
          name: "Business Phone",
          value: "{{business_phone}}",
        },
        businessEmail: {
          name: "Business Email",
          value: "{{business_Email}}",
        },
      },
    },
    appearance: {
      theme: "dark",
      panels: {
        tools: {
          dock: "right",
        },
      },
    },
    template: undefined,
    name: "",
    nameRules: [(v) => !!v || "Name is required"],
  }),
  computed: {
    role() {
      return this.$store.getters.role;
    },
  },
  watch: {
    role() {
      if (this.role) {
        this.options.mergeTags.businessName.value = this.role.business.name;
        this.options.mergeTags.businessEmail.value = this.role.user.email;
        if (this.role.user.phoneNumner)
          this.options.mergeTags.businessPhone.value =
            this.role.user.phoneNumner;
      }
    },
  },
  methods: {
    // ...emailTemplateActions(["fetchEmailTemplate"]),
    ...vETemplateActions([
      "createVendorEmailTemplate",
      "updateVendorEmailTemplate",
    ]),
    // called when the editor is created
    editorLoaded() {
      // Pass your template JSON here
      // this.$refs.emailEditor.editor.loadDesign(this.emailTemplate.design);
    },
    // called when the editor has finished loading
    editorReady() {
      console.log("editorReady");
    },
    saveDesign() {
      const valid = this.$refs.templateForm?.validate();
      if (!valid) return;
      this.$refs.emailEditor.editor.exportHtml((data) => {
        this.createVendorEmailTemplate({
          design: data.design,
          businessId: this.role.business._id,
          name: this.name,
          html: data.html,
        }).then((t) => (this.template = t));
      });
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        this.updateVendorEmailTemplate({
          id: this.template._id,
          vendorEmailTemplate: {
            html: data.html,
          },
        });
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("VENDOR_EMAIL_TEMPLATE")) {
      this.$store.registerModule("VENDOR_EMAIL_TEMPLATE", vendorEmailTemplate);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("VENDOR_EMAIL_TEMPLATE");
  },
};
</script>

<style>
iframe {
  height: 100vh !important;
}

.display-none,
.blockbuilder-branding {
  display: none !important;
}
</style>
